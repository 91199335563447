a.dc {
  color: #57469e;
  text-decoration: none;
}

a.dc:hover {
  color: #57469e;
  text-decoration: none;
}

hr.dc {
  border: 0.5px solid #57469e;
  margin-top: 30px;
  opacity: 0.2;
}

a.qcs {
  color: #497c49;
  text-decoration: none;
}

a.qcs:hover {
  color: #497c49;
  text-decoration: none;
}

hr.qcs {
  border: 0.5px solid #497c49;
  margin-top: 30px;
  opacity: 0.2;
}

br.dc {
  line-height: 10px;
}